import React from 'react'
import { createStore } from 'redux'
import {
  saveDataOnLocalStorage,
  loadDataFromLocalStorage,
  updateAuthToken,
} from './customFunctions'
import axios from 'axios'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  sessionLanguageCode: 'en',
  temp: {},
  sessionToken: null,
  sessionExpire: null,
  userRole: null,
  userEmail: null,
  companies: [],
  numbers: [],
  firstName: null,
  lastName: null,
  company: null,
  companyId: null,
  number: null,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      const key = Object.keys(rest)[0]

      const { toLocalStorage } = rest

      if (toLocalStorage !== false) {
        saveDataOnLocalStorage(key, rest[key])
      }

      return { ...state, ...rest }
    default:
      return state
  }
}

const persistedState = { ...initialState }

Object.keys(localStorage).forEach((key) => {
  const data = loadDataFromLocalStorage(key)

  if (key === 'sessionToken') {
    updateAuthToken(data)
  }

  try {
    persistedState[key] = data
  } catch (e) {}
})

const store = createStore(changeState, persistedState)
export default store
