export const translations = {
  Login: {
    az: 'Daxil ol',
    en: 'Logout',
    ru: 'Вход',
  },
  Logout: {
    az: 'Çıxış',
    en: 'Logout',
    ru: 'Выйти',
  },
  'Company not selected': {
    az: 'Şirkət seçilməyib',
    en: 'Company not selected',
    ru: 'Компания не выбрана',
  },
  'Your Assistant In Accounting': {
    az: 'Mühasibatlıq üzrə köməkçiniz',
    en: 'Your Assistant In Accounting',
    ru: 'Ваш помощник в бухгалтерском учете',
  },
  Name: {
    az: 'Ad',
    en: 'Name',
    ru: 'Имя',
  },
}

export const errors = {}

errors.vat_in_etaxes_in_all_download = {
  1202: {
    az: 'компания не выбрана',
    en: 'компания не выбрана',
    ru: 'компания не выбрана',
  },
  1204: {
    az: 'телефон не выбран',
    en: 'телефон не выбран',
    ru: 'телефон не выбран',
  },
  1205: {
    az: 'параметры для поиска фактур пустые',
    en: 'параметры для поиска фактур пустые',
    ru: 'параметры для поиска фактур пустые',
  },
  1206: {
    az: 'параметры для подтверждения фактур пустые',
    en: 'параметры для подтверждения фактур пустые',
    ru: 'параметры для подтверждения фактур пустые',
  },
  1207: {
    az: 'параметры для отклонения фактур пустые',
    en: 'параметры для отклонения фактур пустые',
    ru: 'параметры для отклонения фактур пустые',
  },
  1220: {
    az: 'не удалось запустить процесс',
    en: 'не удалось запустить процесс',
    ru: 'не удалось запустить процесс',
  },
}
errors.vat_in_etaxes_in_bol_wh_app_approve = errors.vat_in_etaxes_in_all_download
errors.vat_in_etaxes_in_bol_wh_rej_reject = errors.vat_in_etaxes_in_all_download
errors.vat_in_etaxes_in_all_appr_local = {
  1201: {
    az: 'фактуры не выбраны',
    en: 'фактуры не выбраны',
    ru: 'фактуры не выбраны',
  },
  1202: {
    az: 'компания не выбрана',
    en: 'компания не выбрана',
    ru: 'компания не выбрана',
  },
  1204: {
    az: 'коментарий короче 3 символов',
    en: 'коментарий короче 3 символов',
    ru: 'коментарий короче 3 символов',
  },
  1206: {
    az: 'у выбраных фактур не соответствует статус',
    en: 'у выбраных фактур не соответствует статус',
    ru: 'у выбраных фактур не соответствует статус',
  },
  1210: {
    az: 'внутренняя ошибка',
    en: 'внутренняя ошибка',
    ru: 'внутренняя ошибка',
  },
}
errors.vat_in_etaxes_in_bol_rejc_local = errors.vat_in_etaxes_in_all_appr_local
errors.vat_in_etaxes_in_bol_sendtocc = {
  1201: {
    az: 'фактуры не выбраны',
    en: 'фактуры не выбраны',
    ru: 'фактуры не выбраны',
  },
  1202: {
    az: 'компания не выбрана',
    en: 'компания не выбрана',
    ru: 'компания не выбрана',
  },
  1204: {
    az: 'кост центр не выбран',
    en: 'кост центр не выбран',
    ru: 'кост центр не выбран',
  },
  1206: {
    az: 'у выбраных фактур не соответствует статус',
    en: 'у выбраных фактур не соответствует статус',
    ru: 'у выбраных фактур не соответствует статус',
  },
  1207: {
    az: 'данные кост центр не существует',
    en: 'данные кост центр не существует',
    ru: 'данные кост центр не существует',
  },
  1210: {
    az: 'внутренняя ошибка',
    en: 'внутренняя ошибка',
    ru: 'внутренняя ошибка',
  },
}
