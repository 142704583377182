// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import { DEV_ENV, SENTRY_DSN } from './settings'

const inits = () => {
  Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
      return this.charAt(0).toUpperCase() + this.slice(1)
    },
    enumerable: false,
  })

  // if (!DEV_ENV) {
  //   Sentry.init({
  //     dsn: SENTRY_DSN,
  //     integrations: [new Integrations.BrowserTracing()],
  //
  //     // Set tracesSampleRate to 1.0 to capture 100%
  //     // of transactions for performance monitoring.
  //     // We recommend adjusting this value in production
  //     tracesSampleRate: 1.0,
  //   })
  // }

  window.onstorage = () => {
    // on storage changes
  }

  window.onerror = (message, url, lineNumber) => {
    // on error
  }
}

export default inits
