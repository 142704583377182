import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, BrowserRouter, Route, Switch, useLocation } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import './MUIClassNameSetup'
import './App.css'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Logout = React.lazy(() => import('./views/pages/logout/Logout'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  // const dispatch = useDispatch()

  // Object.keys(localStorage).forEach((key) => {
  //   try {
  //     dispatch({ type: 'set', [key]: getFromLocalStorage(key) })
  //   } catch (e) {
  //     console.log(key)
  //   }
  // })

  return (
    <HashRouter>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/logout"
            name="Logout Page"
            render={(props) => <Logout {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
        </Switch>
      </Suspense>
    </HashRouter>
  )
}

export default App
